import type {
  ComponentRef,
  EditorReadyFn,
  GetAppManifestFn,
} from '@wix/yoshi-flow-editor';

import type { EditorScriptFlowAPI, EditorSDK } from './editor/constants/types';
import profileCardConfig from './components/ProfileCard/.component.json';
import { createAppAPI } from './editor/services/app-api';
import * as publicApi from './editor/services/public-api';
import {
  initExperiments,
  isADIHorizontalEnabled,
} from './editor/services/experiments';
import { maybeSetHorizontalLayout } from './editor/services/styles';
import { initMonitoring, toMonitored } from './editor/services/monitoring';
import {
  getMembersAreaContext,
  MembersAreaContext,
} from './editor/services/members-area-api';

let appDefId: string;
let flowAPI: EditorScriptFlowAPI;

const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  options,
  _flowAPI,
) => {
  appDefId = appDefinitionId;
  flowAPI = _flowAPI;
  initExperiments(flowAPI);
  await initMonitoring(editorSDK, options, flowAPI);
  await editorSDK.editor.setAppAPI('', createAppAPI(editorSDK));

  if (!options.firstInstall) {
    return;
  }

  const allowHorizontalInADI = await isADIHorizontalEnabled();
  const isADI = options.origin.type.includes('ADI');
  const isHorizontalLayout = !isADI || allowHorizontalInADI;

  if (isHorizontalLayout) {
    await toMonitored('setHorizontalLayout', () =>
      maybeSetHorizontalLayout(editorSDK, appDefId),
    );
  }
};

const _exports = (editorSDK: EditorSDK) => ({
  public: {
    isHorizontalLayout: () => publicApi.isHorizontalLayout(editorSDK, appDefId),
    refreshApp: () => {
      return publicApi.refreshApp(
        editorSDK,
        flowAPI.experiments,
        'PUBLIC_API_REFRESH',
      );
    },
    setBooleanStyleParamForWidgets: (key: string, value: boolean) =>
      publicApi.setBooleanStyleParamForWidgets(editorSDK, appDefId, key, value),
    setHorizontalLayout: (widgetRef?: ComponentRef) =>
      publicApi.setHorizontalLayout(editorSDK, appDefId, widgetRef),
    setSidebarLayout: () => publicApi.setSidebarLayout(editorSDK, appDefId),
  },
});

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  _contextParams,
  _flowAPI,
) => {
  const membersAreaContext = await getMembersAreaContext(editorSDK);
  const removable = membersAreaContext === MembersAreaContext.V1;

  return appManifestBuilder
    .configureWidget(profileCardConfig.id, (builder) => {
      builder.behavior().set({ removable });
      builder.gfpp().set('widgetPlugins', { behavior: 'HIDE' });
    })
    .build();
};

export { editorReady, _exports as exports };
